<h1 mat-dialog-title>Frist verlängern</h1>
<div mat-dialog-content>
  Die Angebotsfrist endet am {{ this.data.inquiry.quotationDeadline | date }}
</div>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Abbrechen</button>
  <app-spacer></app-spacer>
  <button
    [disabled]="submitting"
    mat-raised-button
    (click)="submit(7)"
    color="primary"
  >
    +7 Tage
  </button>
  <button
    [disabled]="submitting"
    mat-raised-button
    (click)="submit(14)"
    color="primary"
  >
    +14 Tage
  </button>
</mat-dialog-actions>
