import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InquiryService } from 'src/app/shared/services/inquiry.service';
import { SpacerComponent } from 'src/app/components/shared/spacer/spacer.component';
import { addDays } from 'date-fns';
import { Inquiry } from 'src/app/models/inquiry';

@Component({
  selector: 'app-extend-deadline',
  standalone: true,
  templateUrl: './extend-deadline.component.html',
  styleUrls: ['./extend-deadline.component.scss'],
  imports: [CommonModule, AppMaterialModule, SpacerComponent],
})
export class ExtendDeadlineComponent {
  dialogRef = inject(MatDialogRef);
  inquiryService = inject(InquiryService);
  submitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      inquiry: Inquiry;
    }
  ) {}

  submit(extensionInDays: number) {
    const newDeadline = addDays(
      new Date(this.data.inquiry.quotationDeadline),
      extensionInDays
    );
    this.inquiryService
      .changeInquiryDeadline(this.data.inquiry.id, newDeadline)
      .subscribe({
        next: (inquiry) => {
          this.dialogRef.close(inquiry);
          this.submitting = false;
        },
      });
  }
}
